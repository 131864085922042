<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getCrftsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="工艺编号：">
                <el-input size="small" v-model.trim="searchForm.crft_no" clearable placeholder="请填写工艺编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="工艺类型：">
                <el-select size="small" v-model.trim="searchForm.crft_type" placeholder="请选择工艺类型" clearable @visible-change="getCrftType($event)">
                  <el-option
                      v-for="item in crftTypeGroupOpt"
                      :key="item.id"
                      :label="item.param1"
                      :value="item.param1">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="单据状态：">
                <selectStatus ref="selectStatus" @selectStatusRow="getStatusVal"></selectStatus>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="时间范围：">
                <div class="block">
                  <el-date-picker
                      size="small"
                      v-model="searchForm.timeValue"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getCrftsNow()" class="vg_ml_16">查询</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table class="vg_pointer" ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" @select="selectRows" @select-all="selectRows" @row-dblclick="dbClickJp" border>
            <el-table-column type="selection" width="48"  align="center"/>
            <el-table-column label="工艺编号" prop="crft_no"/>
            <el-table-column label="工艺描述" :show-overflow-tooltip='true' prop="crft_name" />
            <el-table-column label="工艺类型" prop="crft_type"/>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina"/>
            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" :show-overflow-tooltip='true'/>
            <el-table-column label="单据状态" :show-overflow-tooltip='true' align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{helper.getStatusName(scope.row.status).name}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {crftAPI} from "@api/modules/crft"
import pubPagination from "@/components/common/pubPagination";
import {optnAPI} from "@api/modules/optn";
import selectStatus from "@/views/component/selectStatus";
export default {
name: "CrftList",
  components: {
    selectStatus,
    pubPagination
  },
  data() {
    return {
      searchForm: {
        crft_no: '',
        crft_type:'',
        status:null,
        timeValue:{
          startTime:null,
          endTime:null
        }
      },
      currentPage: 1,
      totalPage:0,
      tableData: [],
      multiSelection: [],
      crftTypeGroupOpt:[],
      btn:{},
    }
  },
  created() {
    this.initData()
  },
  watch:{
    $route(to,from){
      if(from.path === '/crft_add' || from.path === '/crft_edit'){
        this.initData()
      }
    },
  },
  methods: {
    initData(){
      this.getCrfts();
    },
    // 获取工艺信息
    getCrfts() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      get(crftAPI.getCrfts,
          {
            crft_no: this.searchForm.crft_no,
            crft_type: this.searchForm.crft_type,
            status: this.searchForm.status,
            start_time: timeNewVal.startTime,
            end_time: timeNewVal.endTime,
            page_no: this.currentPage,
          })
          .then(res => {
            if(res.data.code === 0){
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total
              this.btn = res.data.data.btn;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 获取工艺类型
    getCrftType(flag){
      // if(flag === true && this.crftTypeGroupOpt.length === 0){
      //   this.crftTypeGroupOpt = options.crftOpts
      // }
      get(optnAPI.getAllContent,{perm_id : 10002})
          .then(res=>{
            if(res.data.code === 0) {
              this.crftTypeGroupOpt = res.data.data
            }
          })
    },
    // //工艺类型
    // formatCrftType(row){
    //   if(row.crft_type === 1){
    //     return '车缝要求'
    //   } else if(row.crft_type === 2){
    //     return '间距要求'
    //   } else if(row.crft_type === 3){
    //     return '拉力要求'
    //   }
    // },
    // 刷新
    buttonRefresh(){
      this.searchForm = {
        crft_no: '',
        crft_type:'',
        status:null,
        timeValue:{
          startTime:null,
          endTime:null
        }
      };
      this.$refs.selectStatus.changeValue()
      this.currentPage = 1
      this.initData()
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    // 查询方法
    getCrftsNow(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.getCrfts()
    },
    // 状态查询
    getStatusVal(val){
      this.searchForm.status = val
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.getCrfts()
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection){
      this.btn.delete = this.helper.isDelete(selection)
    },
    // 新增
    addRow(){
      const permId = this.$route.query.perm_id
      this.jump(`/crft_add?perm_id=${permId}`)
    },
    //删除提示
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        this.mBox()
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delRow()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.$refs.multiTable.clearSelection();
      });
    },
    // 删除
    delRow(){
      const ids = []
      this.multiSelection.map((item)=>{
        ids.push(item.crft_id)
      })
      post(crftAPI.deleteCrftByIds,{'crft_id_list':ids})
          .then(res=>{
            if(res.data.code === 0){
              let mg = res.data.msg;
              let tp = 'success';
              this.$message({message:mg,type:tp});
              this.initData()
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
              this.initData()
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
            this.initData()
          })
    },
    // 双击row跳转到详情编辑
    dbClickJp(row){
      const permId = this.$route.query.perm_id
      this.jump('/crft_edit',{perm_id:permId,form_id:row.crft_id})
    }
  }
}
</script>

<style scoped>

</style>