<template>
    <el-select v-model="value" placeholder="请选择单据状态" @change="selectStatus()" size="small" clearable>
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
</template>

<script>
export default {
  name: "selectStatus",
  data(){
    return{
      value:null,
      options:[
        {
          value:0,
          label:'草拟'
        },
        {
          value:1,
          label:'在批'
        },
        {
          value:2,
          label:'生效'
        }
      ]
    }
  },
  methods:{
    selectStatus(){
      this.$emit('selectStatusRow',this.value)
    },
    changeValue(){
      this.value = null
    }
  },
}
</script>

<style scoped lang="scss">

</style>